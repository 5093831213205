var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.hideNav
      ? _c(
          "div",
          { staticClass: "menu-nav" },
          _vm._l(_vm.eventTypes, function (type) {
            return _c(
              "a",
              {
                key: type.id,
                class: { active: _vm.selectedType == type.id },
                attrs: { href: type.id },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.selectType(type.id)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(type.title) + "\n        ")]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "events" },
      [
        _c(
          "div",
          { staticClass: "date-filter" },
          [
            _c("v-select", {
              attrs: { options: _vm.dateOptions, clearable: false },
              scopedSlots: _vm._u([
                {
                  key: "open-indicator",
                  fn: function ({ attributes }) {
                    return [
                      _c("span", _vm._b({}, "span", attributes, false), [
                        _c("img", { attrs: { src: "/img/selector.png" } }),
                      ]),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selectedMonth,
                callback: function ($$v) {
                  _vm.selectedMonth = $$v
                },
                expression: "selectedMonth",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.eventsToDisplay.length
          ? _c("div", { staticClass: "no-events" }, [
              _c("h4", [
                _vm._v("No events for the selected date range and type."),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.eventsToDisplay, function (event) {
          return _c("div", { key: event.date, staticClass: "event" }, [
            _c("div", { staticClass: "event-image" }, [
              _c("img", { attrs: { src: event.img, alt: "" } }),
              _vm._v(" "),
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.getDateDisplay(event.date)) },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "event-info" }, [
              _c("h4", [_vm._v(_vm._s(event.title))]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Date: ")]),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.getDateDisplay(event)) },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Time: ")]),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.getTimeDisplay(event)) },
                }),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [_vm._v("Event Type: ")]),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.getEventTypesDisplay(event)),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { domProps: { innerHTML: _vm._s(event.description) } }),
              _vm._v(" "),
              event.cta.value
                ? _c("p", [
                    _c(
                      "a",
                      {
                        staticClass: "button",
                        attrs: {
                          href: event.ctaLink,
                          target: event.cta.target,
                        },
                      },
                      [_vm._v(_vm._s(event.cta.customText))]
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }