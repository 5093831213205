var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "menu-nav" },
        _vm._l(_vm.menu.menuSections, function (section) {
          return _c(
            "a",
            {
              key: section.id,
              class: { active: _vm.selectedItem.id == section.id },
              attrs: { href: section.id },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.selectItem(section.id)
                },
              },
            },
            [
              _vm._v(
                "\n            " + _vm._s(section.title) + "\n            "
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "menu-sections" }, [
      _c("div", { staticClass: "section menu-section parties" }, [
        _c("div", { staticClass: "image-slider" }, [
          _c(
            "div",
            { staticClass: "section-slider-wrap" },
            [
              _c(
                "carousel",
                { attrs: { "per-page": 3, "center-mode": true, speed: 1000 } },
                _vm._l(_vm.selectedItem.images, function (img) {
                  return _c("slide", { key: img.itemDescription }, [
                    _c("img", { staticClass: "img", attrs: { src: img.url } }),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "menu-content container" },
          _vm._l(_vm.selectedItem.items, function (item) {
            return _c("div", { key: item.name, staticClass: "menu-item" }, [
              _c("h4", [_vm._v(_vm._s(item.name))]),
              _vm._v(" "),
              _c("div", {
                staticClass: "item-description",
                domProps: { innerHTML: _vm._s(item.description) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "item-prices" }, [
                _c("div", { staticClass: "item-price" }, [
                  _vm._v(_vm._s(item.price)),
                ]),
              ]),
              _vm._v(" "),
              item.cta.value
                ? _c("div", { staticClass: "item-cta" }, [
                    _c("a", {
                      staticClass: "button",
                      attrs: { href: item.cta.value, target: item.cta.target },
                      domProps: {
                        innerHTML: _vm._s(
                          item.cta.customText ? item.cta.customText : "Book Now"
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }